
import { useNavigate } from 'react-router-dom';

export const BottomNav = () => {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate('/');
  }

  const handleNewWord = () => {
    navigate('/play-word');
  }

  const handleWord = () => {
    navigate('/word');
  }

  const handleWordList = () => {
    navigate('/words');
  }


  return (
    <>
    <footer class="bg-main fixed bottom-0 left-0 w-full">
  

</footer>
    </>);
}