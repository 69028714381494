// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCZnEbUbukJUbeFWbjZ2m5NDl_wKDoN_sw",
  authDomain: "vocab-vault-75ee6.firebaseapp.com",
  projectId: "vocab-vault-75ee6",
  storageBucket: "vocab-vault-75ee6.appspot.com",
  messagingSenderId: "607757130641",
  appId: "1:607757130641:web:ba9f834a3765224ef91830",
  measurementId: "G-JBHC0DVDP3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export default app