import { getFirestore, doc, getDocs, setDoc, serverTimestamp, collection } from "firebase/firestore";
import { db } from "../lib/firebase.config";

const Firestore = {

  readDocs: (...args) => {
    const [collectionName] = args;
    let docs = []
    const ref = collection(db, collectionName)
    return new Promise(async resolve => {
        try {
            const snapshots = await getDocs(ref)
            snapshots.forEach(doc => {
                const d = {...doc.data()}
                docs.push(d)
            })
            resolve(docs)
        } catch(e) {
            console.log(e)
        }
    })
  },


  writeDoc: (...args) => {
    const [inputs, collectionName] = args;

    return new Promise( async resolve => {
      try {
        const randomIndex = Math.floor(Math.random()*1000000000)
        const docRef = doc(db, collectionName, `${randomIndex}`);
        await setDoc(docRef, {wordName: inputs.wordName, createdAt: serverTimestamp() });
        resolve('new doc successfully inserted');
      }
      catch(e) {

      }
    })
  }
}

export default Firestore