import { useAuthContext } from '../context/AuthContext'
import { useEffect, useMemo } from 'react';
import {
  Button,
  Checkbox,
  FileInput,
  Label,
  Radio,
  RangeSlider,
  Textarea,
  TextInput,
  ToggleSwitch,
  Avatar, Dropdown, Card
} from "flowbite-react";

import { useNavigate } from 'react-router-dom';

const LogIn = () => {
  const { login, currentUser } = useAuthContext()
  return (
    !currentUser &&
    <div onClick={login}>Sign in</div>
  );
};

const LogOut = () => {
  const { logout, currentUser } = useAuthContext()
  return (
    !!currentUser &&
    <div onClick={logout}>Sign out</div>
  );
};


export const NavBar = ({title}) => {

  const navigate = useNavigate();
  const goHome = () => {
    navigate('/');
  }

  const { currentUser, authenticate } = useAuthContext();

  const username = useMemo(() => {
    return currentUser?.displayName || "Profile"
  },[currentUser])

  const avatar = useMemo(() => {
    return !!currentUser ?
      <Avatar rounded className="avatar" img={currentUser?.photoURL} alt={currentUser?.displayName } width="34" height="34" />:<div>Log In</div>
  },[currentUser])

  useEffect( () => {
    authenticate();
  });


  return (
    <>
      <header class="h-16 bg-main sticky top-0 z-50 top-0 left-0 w-full">
        <div class="container mx-auto px-4">
          <div class="flex justify-between items-center py-4">
            <h1 class="text-2xl font-bold" onClick={() =>{ goHome()}}>{title ? title:"Vocab Vault"}</h1>
          </div>
        </div>
      </header>
    </>
  );
}