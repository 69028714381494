import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Provider from './context/FirestoreContext';
import AuthProvider from './context/AuthContext';

/* ROUTES*/
import { Word } from './routes/Word';
import { Words } from './routes/Words';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <Provider>
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<App />} />
          <Route path="/words" element={<Words />} />
      </Routes>
    </BrowserRouter>
    </Provider>
  </AuthProvider>

  
);