import { createContext, useReducer } from "react";
import Firestore from "../handlers/firestore"

const { readDocs } = Firestore

export const FirestoreContext = createContext();

const handleOnChange = (state, e) => {
  if (e.target.name === 'file') {
    return { ...state.wordStore, file: e.target.files[0], path: URL.createObjectURL(e.target.files[0])}
  } else {
    return {...state.wordStore, title: e.target.value}
  }
}

const initialState = {
    word: '',
    definition: '', 
    players: [], 
    wordStore: { title: null, file: null, path: null}, 
    isCollapsed: false
}

function reducer(state, action) {
  switch(action.type  ) {
      case 'setItem':
        return {
          ...state, 
          words: [state.wordStore, ...state.words],
          count: state.words.length + 1, 
          wordStore: { title: null, file: null, path: null}
        }
      case 'setPlayer':
        return {
            ...state, 
            players: [state.players, ...state.players],
            count: state.players.length + 1, 
            wordStore: { title: null, file: null, path: null}
          }
      case 'setWords':
        return {
          ...state, 
          words: action.payload.words,
        }
        case 'setPlayers':
        return {
          ...state, 
          players: action.payload.players,
        }
      case "setInputs": 
      return {
        ...state, 
        wordStore: handleOnChange(state, action.payload.value)
      }
      case 'collapse':
        return {
          ...state, 
          isCollapsed: action.payload.bool
        }
      default : return state
  }
}

const Provider = ({ children }) => {
  const [wordStore, dispatch] = useReducer( reducer, initialState );
  
  const readWords = async () => {
    const words = await readDocs("words")
    dispatch({type: "setWords", payload: { words }})
  }

  const readPlayers = async () => {
    const players = await readDocs("players")
    dispatch({type: "setPlayers", payload: { players }})
  }

  return <FirestoreContext.Provider value={{wordStore, dispatch, readWords, readPlayers}}>{children}</FirestoreContext.Provider>
}

export default Provider;