import logo from './logo.svg';
import './App.css';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Firestore from "./handlers/firestore";
import { useAuthContext } from './context/AuthContext'
import { useContext } from 'react';
import { FirestoreContext } from "./context/FirestoreContext"
import { Layout } from './components/Layout'
import { Button, Checkbox, Label, Modal, TextInput } from "flowbite-react";

const { writeDoc, readDocs } = Firestore;

function App() {

  const navigate = useNavigate();
  
  const { wordStore } = useContext( FirestoreContext );
  const { currentUser, authenticate } = useAuthContext();

  const [openModal, setOpenModal] = useState(true);
  const [email, setEmail] = useState('');

  useEffect(() => {
    authenticate()
  },[])

  const handleSubmit = () => {
    const inputs = {
      wordName: 'rubix-race'
    }
    writeDoc(inputs, "word-plays").then(console.log);
  }

  function onCloseModal() {
    setOpenModal(false);
    setEmail('');
  }


  return (<>
    <Layout>
      Welcome to vocabular vault...
   </Layout>
   </>

  );
}

export default App;
